:root {
  --font-fallback: -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto',
    'Segoe UI', 'Helvetica Neue', sans-serif;
  // Deprecated
  --tui-heading-font: 'Manrope', var(--font-fallback);
  --tui-text-font: 'Manrope', var(--font-fallback);
  // Fonts
  --tui-font-heading: var(--tui-heading-font);
  --tui-font-heading-1: bold 3.125rem/3.5rem var(--tui-font-heading);
  --tui-font-heading-2: bold 2.75rem/3rem var(--tui-font-heading);
  --tui-font-heading-3: bold 2.25rem/2.5rem var(--tui-font-heading);
  --tui-font-heading-4: bold 1.75rem/2rem var(--tui-font-heading);
  --tui-font-heading-5: bold 1.5rem/1.75rem var(--tui-font-heading);
  --tui-font-heading-6: bold 1.25rem/1.5rem var(--tui-font-heading);
  --tui-font-text: var(--tui-text-font);
  --tui-font-text-xl: normal 1.1875rem/1.75rem var(--tui-font-text);
  --tui-font-text-l: normal 1.0625rem/1.5rem var(--tui-font-text);
  --tui-font-text-m: normal 0.9375rem/1.5rem var(--tui-font-text);
  --tui-font-text-s: normal 0.8125rem/1.25rem var(--tui-font-text);
  --tui-font-text-xs: normal 0.6875rem/1rem var(--tui-font-text);
  // Sizes
  --tui-radius-xs: 0.25rem;
  --tui-radius-s: 0.5rem;
  --tui-radius-m: 0.625rem;
  --tui-radius-l: 0.625rem;
  --tui-height-xs: 1.5rem;
  --tui-height-s: 2rem;
  --tui-height-m: 2.75rem;
  --tui-height-l: 3.5rem;
  // Input padding
  --tui-padding-s: 0.5rem;
  --tui-padding-m: 0.75rem;
  --tui-padding-l: 1rem;
  --tui-padding-xl: 1.25rem;
  --tui-padding-xxl: 1.5rem;
  // Misc
  --tui-duration: 0.3s;
  // Special colors
  --tui-disabled-opacity: 0.56;
  --tui-autofill: #fff5c0;
  // Base colors
  --tui-base-01: #fff; // background and icons on inverted background
  --tui-base-02: #f6f6f6; // secondary background, marker icons background
  --tui-base-03: #ededed; // basic border
  --tui-base-04: #d7d7d7; // hovered borders
  --tui-base-05: #b0b0b0; // interface icons
  --tui-base-06: #959595; // hovered interface icons
  --tui-base-07: #808080; // inverted background
  --tui-base-08: #333; // dark interface icons
  --tui-base-09: #000; // icons on inverted background
  --tui-primary: #67605a; // primary buttons, background
  --tui-primary-hover: #504b46; // primary buttons hover
  --tui-primary-active: #393632; // primary buttons pressed
  --tui-primary-text: #fff; // text on primary background
  --tui-link: #67605a;
  --tui-link-hover: #504b46;
  --tui-secondary: #d2c5b7; // inputs and secondary buttons
  --tui-secondary-hover: #b6ab9f; // inputs and secondary buttons hover
  --tui-secondary-active: #978d83; // inputs and secondary buttons pressed
  --tui-accent: #ff7043; // fill and outline of accent elements
  --tui-accent-hover: #ff9a94; // accent elements hover
  --tui-accent-active: #e7716a; // accent elements pressed
  --tui-accent-text: #fff; // text on accent background
  --tui-selection: rgba(112, 182, 246, 0.12); // selected text background
  --tui-focus: rgba(51, 51, 51, 0.64); // focus ring color
  --tui-clear: rgba(0, 0, 0, 0.08); // translucent dark fill
  --tui-clear-disabled: rgba(0, 0, 0, 0.04); // translucent dark fill disabled
  --tui-clear-hover: rgba(0, 0, 0, 0.16); // translucent dark fill hover
  --tui-clear-active: rgba(0, 0, 0, 0.2); // translucent dark fill pressed
  --tui-clear-inverse: rgba(255, 255, 255, 0.16); // translucent light fill
  --tui-clear-inverse-hover: rgba(
    255,
    255,
    255,
    0.24
  ); // translucent light fill hover
  --tui-clear-inverse-active: rgba(
    255,
    255,
    255,
    0.4
  ); // translucent light fill pressed
  --tui-elevation-01: #fff; // background of elevated elements
  --tui-elevation-02: #fff; // background of elevated elements
  // Statuses
  --tui-error-fill: rgba(
    244,
    87,
    37,
    1
  ); // icons and decorative elements with error status
  --tui-error-bg: rgba(244, 87, 37, 0.12); // translucent error background
  --tui-error-bg-hover: rgba(
    244,
    87,
    37,
    0.24
  ); // translucent hover error background
  --tui-success-fill: rgba(
    74,
    201,
    155,
    1
  ); // icon and decorative elements with success status
  --tui-success-bg: rgba(74, 201, 155, 0.12); // translucent success background
  --tui-success-bg-hover: rgba(74, 201, 155, 0.24); // translucent success hover
  --tui-warning-fill: rgba(
    255,
    199,
    0,
    1
  ); // icon and decorative elements with warning status
  --tui-warning-bg: rgba(255, 199, 0, 0.12); // translucent warning background
  --tui-warning-bg-hover: rgba(
    255,
    199,
    0,
    0.24
  ); // translucent warning background
  --tui-info-fill: rgba(
    112,
    182,
    246,
    1
  ); // icon and decorative elements with info status
  --tui-info-bg: rgba(112, 182, 246, 0.12); // translucent info background
  --tui-info-bg-hover: rgba(112, 182, 246, 0.24); // translucent info background
  --tui-neutral-fill: rgb(
    121,
    129,
    140
  ); // icon and decorative elements with neutral status
  --tui-neutral-bg: rgba(121, 129, 140, 0.12); // translucent info background
  --tui-neutral-bg-hover: rgba(
    121,
    129,
    140,
    0.24
  ); // translucent info background
  // Text
  --tui-text-01: rgba(27, 31, 59, 1);
  --tui-text-02: rgba(27, 31, 59, 0.65);
  --tui-text-03: rgba(27, 31, 59, 0.4);
  --tui-positive: #3aa981;
  --tui-positive-hover: #7ac5aa;
  --tui-negative: #dd4c1e;
  --tui-negative-hover: #e38163;
  // Modifiers for dark background
  --tui-error-fill-night: rgba(255, 140, 103, 1);
  --tui-error-bg-night: rgba(244, 87, 37, 0.32);
  --tui-error-bg-night-hover: rgba(244, 87, 37, 0.4);
  --tui-success-fill-night: rgb(74, 201, 155);
  --tui-success-bg-night: rgba(74, 201, 155, 0.32);
  --tui-success-bg-night-hover: rgba(74, 201, 155, 0.4);
  --tui-warning-fill-night: rgb(255, 199, 0);
  --tui-warning-bg-night: rgba(255, 199, 0, 0.32);
  --tui-warning-bg-night-hover: rgba(255, 199, 0, 0.4);
  --tui-info-fill-night: rgb(112, 182, 246);
  --tui-info-bg-night: rgba(112, 182, 246, 0.32);
  --tui-info-bg-night-hover: rgba(112, 182, 246, 0.4);
  --tui-neutral-fill-night: rgb(149, 155, 164);
  --tui-neutral-bg-night: rgba(149, 155, 164, 0.32);
  --tui-neutral-bg-night-hover: rgba(149, 155, 164, 0.48);
  --tui-autofill-night: rgb(85, 74, 42);
  //
  --tui-text-01-night: rgba(255, 255, 255, 1);
  --tui-text-02-night: rgba(255, 255, 255, 0.72);
  --tui-text-03-night: rgba(255, 255, 255, 0.6);
  --tui-link-night: #6788ff;
  --tui-link-night-hover: #526ed3;
  --tui-positive-night: #44c596;
  --tui-positive-night-hover: #3aa981;
  --tui-negative-night: #ff8c67;
  --tui-negative-night-hover: #bb593a;
  //
  --tui-support-01: #a8cef1;
  --tui-support-02: #3682db;
  --tui-support-03: #8dda71;
  --tui-support-04: #34b41f;
  --tui-support-05: #e29398;
  --tui-support-06: #b8474e;
  --tui-support-07: #fcc068;
  --tui-support-08: #ff8a00;
  --tui-support-09: #dab3f9;
  --tui-support-10: #7b439e;
  --tui-support-11: #fee797;
  --tui-support-12: #fcbb14;
  --tui-support-13: #ea97c4;
  --tui-support-14: #bd65a4;
  --tui-support-15: #7fd7cc;
  --tui-support-16: #2fad96;
  --tui-support-17: #d4aca2;
  --tui-support-18: #9d6f64;
  --tui-support-19: #d2e9a2;
  --tui-support-20: #aadc42;
  --tui-support-21: #a0c5df;
  --tui-chart-0: var(--tui-primary);
  --tui-chart-1: var(--tui-support-13);
  --tui-chart-2: var(--tui-support-21);
  --tui-chart-3: var(--tui-support-11);
  --tui-chart-4: var(--tui-base-05);
}
