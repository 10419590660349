$app-full-hd-width: 1920px;
$app-max-page-width: 1366px;
$max-page-width-small: 768px;
$max-page-width-medium: 900px;

$app-font-size-xs: 10px;
$app-font-size-small: 14px;
$app-font-size-default: 16px;
$app-font-size-big: 18px;

$app-font-size-heading: 20px;
$app-font-size-logo: 30px;
$app-font-size-banner: 30px;

$app-font-xs: 12px;
$app-font-small: 14px;
$app-font-default: 16px;
$app-font-large: 18px;
$app-font-heading6: 20px;
$app-font-heading5: 24px;
$app-font-heading4: 28px;
$app-font-heading3: 32px;
$app-font-heading2: 36px;
$app-font-heading1: 40px;

$app-breakpoint-phone: 767px;
$app-breakpoint-tab: 1024px;
$app-breakpoint-desktop: 1366px;

$padding-sm: 10px;
$padding-md: 15px;
$padding-lg: 20px;

$header-height: 40px;
$app-header-height: 60px;
$sub-menu-1-height: 30px;

$app-font-boldest: 900;
$app-font-bolder: 800;
$app-font-bold: 700;
$app-font-normal: 600;
$app-font-light: 400;
$app-font-lighter: 300;
$app-font-lightest: 200;

$app-font-size-display-4: 112px;
$app-font-size-display-3: 54px;
$app-font-size-display-2: 45px;
$app-font-size-display-1: 34px;
$app-font-size-headline-2: 30px;
$app-font-size-headline: 24px;
$app-font-size-title: 20px;
$app-font-size-subheading-2: 16px;
$app-font-size-subheading-1: 15px;
$app-font-size-body-2: 14px;
$app-font-size-body-1: 14px;
$app-font-size-button: 14px;
$app-font-size-caption: 12px;
$app-font-size-input: inherit;
