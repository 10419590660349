@import 'media';

@mixin shadow-all {
  box-shadow: 0px 5px 10px 0px var(--tui-base-03);
}

@mixin phone {
  @media #{$tui-mobile} {
    @content;
  }
}

@mixin tablet {
  @media #{$tablet-lg-interval} {
    @content;
  }
}

@mixin desktop-sm {
  @media #{$desktop-s-interval} {
    @content;
  }
}

@mixin desktop {
  @media #{$desktop-m-min} {
    @content;
  }
}

@mixin glass-effect() {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.7px);
  -webkit-backdrop-filter: blur(4.7px);
  border: 1px solid rgba(255, 255, 255, 0.46);
}

// @mixin desktop {
//   @media #{$tablet-lg-min} {
//     font-size: 50px;
//   }
//   @media only screen and (max-width: $app-breakpoint-desktop) {
//     @content;
//   }
// }

// @mixin custom($max-width) {
//   @media only screen and (max-width: $max-width) {
//     @content;
//   }
// }
