@import 'variables';
@import 'mixins';
@import 'colors';

// * {
//   outline: 1px solid #f00 !important;
// }

* {
  font-family: var(--tui-font-text);
  font: var(--tui-font-text-m);
  // font-size: $app-font-size-default;
  // line-height: 1.4;
  box-sizing: border-box;
}

body {
  background-color: var(--tui-base-01);
  color: var(--tui-text-02);
  min-width: 320px;
  margin: unset;
}

p {
  margin: unset;
}

.text-center {
  text-align: center;
}

.app-table {
  display: table;
  width: 100%;
  .app-table-row {
    display: table-row;
    width: 100%;
    .app-table-col {
      display: table-cell;
      padding: 5px;
      border-bottom: 1px solid var(--tui-base-06);
      &.text-center {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
    &:last-child {
      border-bottom: unset;
    }
  }
}

.owl-carousel {
  height: 100%;
  .owl-stage-outer {
    height: 100%;
    owl-stage {
      display: block;
      height: 100%;
      > div {
        height: 100%;
        .owl-stage {
          height: 100%;
          display: flex;
          .owl-item {
            height: inherit;
            display: flex;
            > div {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

label {
  margin-bottom: unset;
}

.app-modal {
  .app-modal-header {
    margin-bottom: 20px;
    .modal-title {
      font-size: $app-font-size-big;
      color: var(--tui-primary-active);
      font-weight: bold;
    }
  }

  .app-modal-body {
    margin-bottom: 20px;
  }

  .app-modal-footer {
    padding-top: 20px;
    text-align: right;
    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.app-page-container {
  padding: 20px;
  max-width: $app-max-page-width;
  margin: auto;
  @include tablet {
    padding: 15px;
    .content {
      gap: 15px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @include phone {
    padding: 10px;
    .content {
      grid-template-columns: 1fr;
    }
  }
  &-sm {
    max-width: $max-page-width-small;
  }
  &-md {
    max-width: $max-page-width-medium;
  }
  &-full-height {
    min-height: 90vh;
  }
}

.app-product-image-container {
  width: 100%;
  position: relative;
  padding-top: 125%; /* 4:5 Aspect Ratio */
  overflow: hidden;
  .app-product-image {
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.firebase-emulator-warning {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.item-img {
  overflow: hidden;
  &__thumb {
    border-radius: 8px;
    width: 80px;
  }
}

.app-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
  &__1 {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
  &__2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}

.app-link {
  color: var(--tui-primary-active);
  &:hover {
    text-decoration: unset;
    color: var(--tui-accent);
  }
}

.app-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  &.hidden {
    display: none;
  }
  &::before {
    content: '';
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    cursor: wait;
  }
}

.loading-image {
  pointer-events: none;
  animation: loading-animation 1.5s infinite;

  @keyframes loading-animation {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
}

.app-d-block {
  display: block;
}
